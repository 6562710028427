.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 30px !important;
    line-height: 1;
    opacity: .75;
    color: black !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bgimg.placementcard.placement_Cards.slick-prev {
    left: -35px !important;
    /* z-index: 1; */
}

.bgimg.placementcard.placement_Cards.slick-next {
    right: -25px !important;
    /* z-index: 1; */
}

.placement_Description_Container {
    margin-top: 50px;
}

.paragraph {
    text-align: justify;
}

.placement_Row {
    margin-bottom: 40px;
}





/* css for placement highlights container */

.highlightHeading {
    text-align: center;
}

.bgcontainer2 {
    background-color: #218bbb;
    padding: 74px;
    border-radius: 5px;
    margin-top: 40px;
}


.align_center_div {
    text-align: center;
    border-right: 2px solid #ffffff;
}

.align__center_div {
    text-align: center;
}

.placementCounter {
    color: white;
}

.counterDescription {
    color: white;
}
.bgcontainer1{
    margin-bottom: 50px;
}
/* css for StudPlacements.js */

.bgimg {
    position: relative;
    padding: 90px;
    /* height: 700px;
    display: flex; */
    box-shadow: 10px 19px 53px rgba(48, 41, 41, 0.2);
    margin-top: 50px;
}

.studimg {
    border-bottom: 1px solid black;
    border-radius: 5px;
    border-bottom: 2px solid rgb(237, 200, 213);
    /* box-shadow: 10px 10px rgb(65, 169, 204); */

}

.stdnames {
    margin-top: 15px;
    text-align: center;
}

.content {
    /* position: relative;
    padding-top: 70px;
    padding-left: 120px;
    text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.studentName {
    font-size: 20px;
}

.placementcard {
    /* width: 460px;
    margin-left: 50px; */
}

.card.placement_Cards {
    border: none;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.col.placed_Std_Col {
    margin: auto;
}

.p1 {
    font-weight: 600;
}

.p2 {
    font-size: 36px;
    color: #218bbb;
    font-weight: 700;
}

.p3 {
    font-size: 18px;
    font-weight: 500;
    color: #222f5d;
}
.firstColumn{
    margin-top: 6px;
}
@media (max-width: 992px) {
    .align_center_div {
        border-bottom: 2px solid #ffffff;
        border-right: none;
        padding: 10px;
    }

    .align__center_div {
        padding: 10px;
        border-bottom: 2px solid #ffffff;
    }

    .paragraph {
        font-size: 14px;
    }

    .images {
        text-align: center;
        margin-bottom: 10px;
    }

    .counterDescription {
        font-size: 16px;
    }

    .p2 {
        font-size: 24px;
        margin: 2rem 0px !important;
        margin-left: 125px !important ;
    }

}
.placementDescripCol{
text-align: justify;
}

@media ((min-width: 320px) and (max-width: 991px)) {
    .placementcard {
        width: 225px;
        margin: auto;
    }

    .bgimg {
        /* padding: 35px; */
        padding: 25px;
        margin-left: auto;
    }

    .content {
        margin-left: -119px;
        margin-top: -44px;
    }

    .placement_Row {
        padding: 0px 25px 0px 25px;
    }

    .bgcontainer2 {
        padding: 40px 15px 40px 15px;
    }
}

@media (max-width: 991px) {
    .placement_Row:nth-child(2) .firstColumn {
        order: 1;
    }

    .placement_Row:nth-child(2) .secondColumn {
        order: 2;
    }
}
@media ((min-width: 991px) and (max-width: 1024px)) {
.paragraph {
   font-size: 14px;
}
.firstColumn{
    margin-top: 68px !important
  }
}

/* @media ((min-width: 1025px) and (max-width: 1440px)) {
  .firstColumn{
    margin-top: 62px !important
  }
    } */
    .training-placement-title{
    font-size: 36px;
    font-weight: 700;
    text-align: left;
    color: #218bbb;
    margin-top: 25px;
    }
    .placement-div{
        text-align: center;
        padding-bottom: 20px;
    }
    @media ((min-width: 320px) and (max-width: 425px)) {
        .training-placement-title{
        font-size: 24px;
        }
    }
