/*--------------------------------------------social media bar---------------------------------------------------*/
/* social media sticky bar */
.icon-bar {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999;
}

/* Style the icon bar links */
.icon-bar a {
    display: block;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease;
    color: white;
    /* font-size: 15px; */
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
    background-color: #000;
}

.facebook {
    background: #218bbb;
    /* border-radius: 50%; */
    border-top-right-radius: 5px;
    color: white;
}

.instagram {
    background: hsl(335, 85%, 52%);
    /* border-radius: 50%; */
    color: white;
}

.whatsapp {
    background: #39dd4c;
    /* border-radius: 50%; */
    color: white;
}

.linkedin {
    background: #007bb5;
    /* border-radius: 50%; */
    color: white;
}

.youtube {
    background: #bb0000;
    border-bottom-right-radius: 5px;
    /* border-radius: 50%; */
    color: white;
}

@media ((min-width: 320px) and (max-width: 1024px)) {
    .icon-bar a {
        display: block;
        text-align: center;
        padding: 10px;
        transition: all 0.3s ease;
        color: white;
        font-size: 12px;
    }
}


@media ((min-width: 740px) and (max-width: 768px)) {

    .icon-bar {

        top: 50% !important;

    }
}