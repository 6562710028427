.backgroundimg {
  background-color: rgb(62, 137, 186);
  height: 230px;
  width: 100%;
}

.main-image img {
  width: 100%;
  height: 100%;
  margin-top: 81px;
}

/* .eduvibe-widget-details {
  padding: 0px 20px ;
} */


.course-detail-content {
  margin-right: 30px;
}

#sticky-position {
  position: sticky;
  position: -webkit-sticky;
  top: 100px;
  color: #fff;
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  font-weight: 500;
  line-height: 1.4;
}

.textlayout {
  font-size: 25px;
  font-weight: 600;
}

.eduvibe-widget {
  background: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 30px 40px;
}

.eduvibe-sidebar.course-details-sidebar .eduvibe-widget {
  padding: 20px;
  border-radius: 10px;
}

.eduvibe-sidebar.course-details-sidebar .eduvibe-widget .eduvibe-widget-details {
  padding: 0 20px 20px;
}

.widget-content ul li {
  color: black;
  list-style: none;
}

.widget-content ul hr {
  font-size: 5px;
  color: black;
}


.widget-content ul {
  padding: 0;
  margin: 0;
  list-style: disc;
}

.eduvibe-widget-details .widget-content ul li {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}

.eduvibe-widget-details .widget-content ul li span {
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
  /* margin-right: 25px; */
}

.buttonlayout {
  width: 100%;
  max-width: 100%;
  margin: 0px 0px;
  /* padding: 10px 10px; */
  background-color: #218BBB !important;
  color: white;
  border: none !important;
  border-radius: 0px !important;
}

.coursetopmargin {
  margin: 20px 0px;
}

.buttonlayout:hover {
  background-color: #0E4D63;
  color: white;
}


.course-intro-heading {
  font-size: 35px;
  font-weight: 700;
}

.detail-content-heading {
  font-size: 18px;
  font-weight: 600;
  padding: 20px 0px 10px 0px;
}

.info-icon {
  display: flex;
  flex-direction: row;
}

.course-duration {
  margin-top: 2px;
  margin-left: 4px;
}

.course-content-space {
  margin: 30px;
  text-align: justify;
}


.course_bullet_point {
  list-style-type: disc;
  /* Use disc for default bullet points */
  margin-left: 20px;
  /* Adjust margin for indentation */
}

.detail-content-title {
  font-size: 18px;
  margin: 10px 0px;
}

li {
  margin-bottom: 0.5rem;
}

/* .listadjustment ul li {
  padding: 7px;
}

.listadjustment ul {
  padding-left: 50px;
} */

@media only screen and (min-width: 767px) and (max-width: 991px) {

  /* .eduvibe-widget {
    padding: 30px 20px;
  } */
  #sticky-position {
    max-width: 60% !important;
    margin: auto !important;
  }
  .buttonlayout {
    width: 100%;
    max-width: 100%;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .eduvibe-widget-details .widget-content ul li span {
    font-size: 14px !important;
  }
.offer-price{
  font-size: 13px !important;
}
  .course-duration {
    margin-top: 1px;
    margin-left: 4px;
  }

  .eduvibe-widget {
    padding: 15px;
  }
}

@media only screen and (max-width: 320px) {
.eduvibe-widget {
  padding: 30px 20px !important;
}
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .buttonlayout {
    width: 100%;
    max-width: 100%;
  }
}


@media only screen and (min-width: 319px) and (max-width: 425px) {
  .course-detail-content {
    width: 100%;
    margin: auto;
  }

  .price-title {
    font-size: 12px;
  }

  .price {
    font-size: 12px;
  }

  #sticky-position {
    width: 100%;
  }

  .main-image img {
    height: 100%;
  }

  .course-intro-heading {
    font-size: 24px !important;
  }

  .detail-content-heading {
    font-size: 18px !important;
  }

  .detail-content-subheading {
    font-size: 14px !important;
  }

  .eduvibe-widget-details .widget-content ul li span {
    font-size: 14px !important;

  }

  .course-duration {
    margin-top: 2px;
  }

  .eduvibe-widget-details {
    padding: 0px !important;
  }
  .buttonlayout {
    width: 100%;
    max-width: 100%;
  }

}
.offer-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
}

.price-title {
  margin-left: 9px;
}